import {captureException, captureMessage} from "@sentry/react";
import {PolIcon} from "components/PolIcon/policon";
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {createPortal} from "react-dom";
import Loader from "v4/components/ui/Loader/Loader";
import PolTableTd from "v4/components/ui/PolTable/components/PolTableTd/PolTableTd";
import PolTableTh from "v4/components/ui/PolTable/components/PolTableTh/PolTableTh";
import 'v4/components/ui/PolTable/PolTable.scss';
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {VIEW_ORDERS_FIELDS} from "v4/data/apiRoutes";
import ViewOrdersList from "v4/features/front/listingViewOrdersManagement/components/ViewOrdersList/ViewOrdersList";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import usePropToComponent from "v4/hooks/usePropToComponent";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";

export default React.memo(function PolTable({
                                                headers,
                                                rows,
                                                data,
                                                tfootSums,
                                                checkFunctions,
                                                status,
                                                rowActions,
                                                entity,
                                                onHeaderClick,
                                                className = "",
                                                toggleRefetch,
                                                styleVars,
                                                hasTFoot = true,
                                                saveClickedId,
                                            }) {
    const {t} = usePolTranslation();
    const [isShowing, toggleShow] = useToggle();
    const [, prepareActions] = usePropToComponent(rowActions);

    // either rows and headers or data must be provided
    if (!rows && !data) {
        const err = new Error("Either rows and headers or data must be provided to PolTable");
        captureException(err);
        throw err;
    }
    // if both rows and headers and data are provided, data is ignored
    if (rows && headers && data) {
        console.warn("Both rows and headers and data are provided to PolTable. Data is ignored");
    }

    // if data is provided, then rows and headers are generated from it or use the default ones
    const tableRows = useMemo(() => {
        return rows ?? data?.results ?? null;
    }, [data, rows]);

    const tableHeaders = useMemo(() => {
        return headers ?? data?.headers ?? [];
    }, [data, headers]);

    const {containsInChecklist, handleCheck, handleCheckAll, isCheckAllChecked} = checkFunctions ?? {};
    const handleRowCheck = (id) => () => handleCheck(id);

    const [{isFinished: isFinishedViewOrders, isLoading: isLoadingViewOrders}, fetchViewOrders] = useFetch();
    const handleSubmitViewOrders = useCallback((displayedColumns, tabId) => {
        fetchViewOrders({
            url: generateUrl(VIEW_ORDERS_FIELDS, {entity}),
            config: {
                method: 'POST',
                body: JSON.stringify({fields: displayedColumns, tabId: tabId ?? null}),
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity]);
    useEffect(() => {
        if (isFinishedViewOrders) {
            if (toggleRefetch) toggleRefetch();
            if (toggleShow) toggleShow();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinishedViewOrders]);

    const portalRef = useRef(null);

    const calculateColumnSum = (columnKey) => {
        return tableRows.reduce(
            (sum, {fields}) => {
                tableHeaders.forEach(({key}) => {
                    if (columnKey === key) {
                        sum += isNaN(getValueOfKey(key, fields)) ? 0 : parseFloat(getValueOfKey(key, fields));
                    }
                })

                return sum;
            }, 0
        );
    }

    const handleAltHover = (e) => {
        const {currentTarget} = e;
        if (e.altKey) {
            currentTarget.classList.add('selectable');
        } else {
            currentTarget.classList.remove('selectable');
        }
    }

    return (
        <div className={`pol-table__wrapper${(tableHeaders?.length < 1 && tableRows?.length < 1) ? ' is-loading' : ''}`}
             ref={portalRef}
             style={styleVars}>
            {status?.isLoading && <Loader/>}
            <div className="pol-table__overflow">
                {handleCheckAll && <label
                    htmlFor="pol-table-check-all">{isCheckAllChecked ? t('unselect-all') : t('select_all')}</label>}
                <table className={`pol-table ${className}`}>
                    <thead>
                    <tr>
                        {(handleCheckAll || handleCheck) && (
                            <th className="pol-table__check-all">
                                {handleCheckAll &&
                                    <input type="checkbox" id="pol-table-check-all" checked={isCheckAllChecked}
                                           onChange={handleCheckAll}/>}
                            </th>
                        )}
                        {tableHeaders.map(({key, label, metadata: {type, sortType}}) => (
                            <PolTableTh key={key}
                                        dataKey={key}
                                        label={label}
                                        onHeaderClick={onHeaderClick}
                                        type={type}
                                        sortType={sortType}/>))}
                        {entity && (
                            <th className="pol-table__view-orders">
                                <PolIcon icon="list" className="toggle-columns" onClick={toggleShow}/>
                                {portalRef.current && createPortal(
                                    <ToggleComponent isShowing={isShowing}
                                                     shouldRemoveFromDom={true}
                                                     className="action-view-orders-list">
                                        <div className="action-view-orders-list__heading">
                                            <p>{t('gestion_colonnes')}</p>
                                            <span className="action-view-orders-list__close"
                                                  onClick={toggleShow}>
                                                <PolIcon icon="times-circle"/>
                                            </span>
                                        </div>
                                        <ViewOrdersList onSubmit={handleSubmitViewOrders}
                                                        onCancel={toggleShow}
                                                        entity={entity}
                                                        isBlocked={isLoadingViewOrders}/>
                                    </ToggleComponent>
                                    , portalRef.current)}
                            </th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {tableRows?.length === 0 && (
                        <tr>
                            <td className="pol-table__no-results" colSpan={999}>
                                <div>
                                    <PolIcon icon="search"/>
                                    {t('no_results')}
                                </div>
                            </td>
                        </tr>
                    )}
                    {tableRows?.map(({fields, metadata, extraFields: {fullname}, rowActions}, index) => (
                        <tr key={index} onMouseMove={handleAltHover} onClick={saveClickedId} id={metadata.id}>
                            {handleCheck && containsInChecklist && (
                                <td data-checkbox>
                                    <input type="checkbox"
                                           checked={containsInChecklist(metadata.id)}
                                           onChange={handleRowCheck(metadata.id)}/>
                                </td>
                            )}
                            {tableHeaders.map(({key, label, metadata: {type}}) => (
                                <PolTableTd key={key}
                                            cellKey={key}
                                            dataLabel={t(label)}
                                            type={type}
                                            value={getValueOfKey(key, fields)}
                                            fullname={fullname}
                                            rowActions={rowActions}
                                            rowMetadata={metadata}
                                            cellMetadata={getMetadataOfKey(key, fields)}/>
                            ))}
                            <td data-type="pol-actions">
                                {prepareActions && prepareActions({...metadata, rowId: metadata.id})}
                            </td>
                        </tr>
                    ))}
                    </tbody>

                    {hasTFoot && tableRows?.length > 0 && (
                        <tfoot>
                        {tableHeaders.find(({metadata: {type}}) => type === 'money') && (
                            <tr>
                                {handleCheck && containsInChecklist && (
                                    <td className="tfoot-label" colSpan={2}><b>{t('total_page')}</b></td>
                                )}
                                {tableHeaders.map(({key, label, metadata: {type}}, index) => {
                                    if (!handleCheck && index === 0) {
                                        return <td key={key} className="tfoot-label" ><b>{t('total_page')}</b></td>
                                    }
                                    if (handleCheck && index === 0) {
                                        return null;
                                    }
                                    if (type === 'money') {
                                        return <PolTableTd key={key} dataLabel={t(label)} type={type}
                                                           value={calculateColumnSum(key)}/>
                                    }
                                    return <td key={key}></td>
                                })}
                                <td></td>
                            </tr>
                        )}
                        {tfootSums?.length > 0 && (
                            <tr>
                                {handleCheck && containsInChecklist && (
                                    <td className="tfoot-label" colSpan={2}><b>{t('total_global')}</b></td>
                                )}
                                {tableHeaders.map(({key, label, metadata: {type}}, index) => {
                                    if (!handleCheck && index === 0) {
                                        return <td key={key} className="tfoot-label" ><b>{t('total_global')}</b></td>
                                    }

                                    if (handleCheck && index === 0) {
                                        return null;
                                    }

                                    const sum = tfootSums.find(sum => Object.keys(sum).includes(key));
                                    if (sum) {
                                        return <PolTableTd key={key}
                                                           dataLabel={t(label)}
                                                           type={type}
                                                           value={sum[key]}/>
                                    }
                                    return <td key={key}></td>
                                })}
                                <td></td>
                            </tr>
                        )}
                        </tfoot>
                    )}
                </table>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    if (!areObjectsEqual(prevProps.headers, nextProps.headers)) return false;
    if (!areObjectsEqual(prevProps.rows, nextProps.rows)) return false;
    if (!areObjectsEqual(prevProps.data, nextProps.data)) return false;
    if (!areObjectsEqual(prevProps.status, nextProps.status)) return false;
    if (!areObjectsEqual(prevProps.checkFunctions?.checkedRows ?? [], nextProps.checkFunctions?.checkedRows ?? [])) return false;

    return areObjectsEqual(prevProps.toggleRefetch, nextProps.toggleRefetch);
})

function areObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function getValueOfKey(headerKey, fields) {
    const filteredFields = fields.filter(({key}) => key === headerKey);

    if (filteredFields.length === 1) return filteredFields[0]?.value;
    if (filteredFields.length > 1) {
        console.warn('Two cells have the same key, check your datas');
        captureMessage('Two cells have the same key, check your datas', {
            level: 'warning',
            extra: {
                headerKey,
                fields,
            }
        });
    }

    return null;
}

function getMetadataOfKey(headerKey, fields) {
    const filteredFields = fields.filter(({key}) => key === headerKey);

    if (filteredFields.length === 1) return filteredFields[0]?.metadata;
    if (filteredFields.length > 1) {
        console.warn('Two cells have the same key, check your datas');
        captureMessage('Two cells have the same key, check your datas', {
            level: 'warning',
            extra: {
                headerKey,
                fields,
            }
        });
    }

    return null;
}
